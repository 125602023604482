.admin-filters-container {
	display: flex;
}

.admin-filter {
	width: 200px !important;
	margin-right: 10px !important;
}

@media (max-width: 420px) {
	.admin-filters-container {
		display: none;
	}
}